import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import Routers from './router';
import 'boxicons/css/boxicons.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app.scss';

const App: React.FC = () => (
    <div className='App'>
        <Provider store={store}>
            <Routers />
        </Provider>
    </div>
);

export default App;
