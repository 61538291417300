import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import { Button, Grid, Group, Loader, PasswordInput, Text, Title } from '@mantine/core';
import { PageHeader } from 'shared/components/pageHeader';
// functions
import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/services/api';
import { showNotification } from '@mantine/notifications';
// assets
import './profile.scss';

const initValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
};

const Profile: React.FC = () => {
    const [password, setPassword] = useState({ ...initValues });
    const [admindata, setAdmindata] = useState({});
    const [isLoading, setisLoading] = useState(true);

    const history = useHistory();

    useEffect(() => {
        userProfile();
    }, []);

    const handleLogin = () => {
        HttpService.put(API_CONFIG.path.changePassword, {
            password: password.currentPassword,
            newPassword: password.confirmPassword,
        })
            .then((response) => {
                showNotification({
                    message: response.message,
                    color: 'green',
                });
            })
            .catch((err) => {
                showNotification({
                    message: err.response.data.message,
                    color: 'red',
                });
            });
    };
    const userProfile = () => {
        HttpService.get(API_CONFIG.path.userProfile)
            .then((response) => {
                setisLoading(false);
                setAdmindata({
                    Role: response.data.role,
                    Email: response.data.email,
                    // UserName: response.data.userName || '',
                    Name: `${response.data.firstName} ${response.data.lastName}`,
                });
            })
            .catch((err) => {
                console.log('  err', err);
            });
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword({
            ...password,
            [event.target.name]: event.target.value,
        });
    };

    const changePassword = () => {
        const { newPassword, currentPassword, confirmPassword } = password;
        if (newPassword.length == 0 || currentPassword.length == 0 || confirmPassword.length == 0) {
            showNotification({
                message: 'Plase fill up empty field',
                color: 'red',
            });
        } else if (newPassword == confirmPassword && newPassword.length > 0) {
            handleLogin();
        } else {
            showNotification({
                message: 'Passwords do not match, please retype',
                color: 'red',
            });
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        history.push('/login');
    };

    const removeData = () => {
        setPassword({ ...initValues });
    };

    const passwordData = [
        {
            title: 'Current Password',
            name: 'currentPassword',
            placeholder: 'your Current Password',
            value: password.currentPassword,
        },
        {
            title: 'New Password',
            name: 'newPassword',
            placeholder: 'your New Password',
            value: password.newPassword,
        },
        {
            title: 'Confirm Password',
            name: 'confirmPassword',
            placeholder: 'Confirm Password',
            value: password.confirmPassword,
        },
    ];

    return (
        <div>
            <PageHeader title='Admin Profile' />
            <div className='profile-info d-flex align-items-center justify-content-between'>
                <Grid className='w-100'>
                    <Grid.Col span={2} className='admin-data-icon '>
                        {' '}
                        <i className='bx bx-user-circle ' />
                    </Grid.Col>
                    <Grid.Col span={10} className='admin-data'>
                        {' '}
                        {!isLoading &&
                            Object.entries(admindata).map(([key, value], index) => (
                                <Text key={index} className='admin-data-wrapper'>
                                    {key} : <span className='admin-data-value'>{`${value}`}</span>
                                </Text>
                            ))}
                        {isLoading && <Loader size='sm' className='ml-2 ' variant='bars' />}
                    </Grid.Col>
                </Grid>
                <div onClick={() => logout()}>
                    <Button color='yellow'>Logout</Button>
                </div>
            </div>

            <div className=' changepassword profile-info align-items-center'>
                <Title order={2} className='mb-4 '>
                    Change Password
                </Title>
                <Grid className='flex-column'>
                    {passwordData.map((data, index) => {
                        const { title, name, placeholder, value } = data;
                        return (
                            <Grid.Col span={5} key={index}>
                                <Group>
                                    <Text component='label' htmlFor='your-password' size='sm' weight={500} className='mb-2'>
                                        {title}
                                    </Text>
                                </Group>
                                <PasswordInput
                                    placeholder={placeholder}
                                    id='your-password'
                                    value={value}
                                    name={name}
                                    onChange={(event) => handleChange(event)}
                                />
                            </Grid.Col>
                        );
                    })}

                    <Grid.Col span={5}>
                        <div>
                            <Button color='yellow' className='mr-2' onClick={() => changePassword()}>
                                Save
                            </Button>
                            <Button color='yellow' onClick={() => removeData()}>
                                Cancel
                            </Button>
                        </div>
                    </Grid.Col>
                </Grid>
            </div>
        </div>
    );
};
export default Profile;
