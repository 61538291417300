export const UserColumns = [
    {
        name: 'First Name',
        selector: (row: any) => row.firstName,
        sort: true,
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: (row: any) => row.lastName,
        sort: true,
        sortable: true,
    },
    {
        name: 'Email',
        selector: (row: any) => row.email,
        sort: true,
        sortable: true,
    },
    {
        name: 'EmailVerified',
        selector: (row: any) => row.isEmailVerified,
        sort: true,
        sortable: true,
    },
    {
        name: 'Signup Date',
        selector: (row: any) => row.createdDate,
        sort: true,
        sortable: true,
    },

    // {
    //   name: "Status",
    //   selector: (row: any) => row.status,
    //   sort: true,
    //   sortable: true,
    //   className: "test",
    // },
];
