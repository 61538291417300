import { ActionIcon, Button, Loader, Modal } from "@mantine/core";
import { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { API_CONFIG } from "shared/services/api";
import HttpService from "shared/services/http.service";
import { customStyles } from "shared/util/tableConfig";
import { emailColumns } from "../utils/tableColums";
import { showNotification } from "@mantine/notifications";

let Id = "";

const Receiversemails = () => {
  const [addBtnLoader, setAddBtnLoader] = useState(false);
  const [emailList, setEmailList] = useState<any>();
  const [isLoading, setisLoading] = useState(true);
  const [openedDeleteModal, setOpenedDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [emailId, setEmailId] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getEmail();
  }, []);
  useEffect(() => {
    setValue("editEmail", emailId);
  }, [emailId]);

  const getEmail = () => {
    setisLoading(true);
    HttpService.get(API_CONFIG.path.emailList)
      .then((response) => {
        setEmailList(response.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(" err", err);
        setisLoading(false);
      });
  };

  const onSubmit = (data: any) => {
    setAddBtnLoader(true);
    setValue("email", "");
    HttpService.post(API_CONFIG.path.addEmail, { email: data.email })
      .then((response) => {
        showNotification({
          message: response.message,
          color: "green",
        });
        getEmail();
        setAddBtnLoader(false);
      })
      .catch((err) => {
        showNotification({
          message: err.response.data.message,
          color: "red",
        });
        setAddBtnLoader(false);
      });
  };

  const deleteEmail = () => {
    HttpService.deleteRequest(`v1/emails/${Id}`)
      .then((response) => {
        showNotification({
          message: response.message,
          color: "green",
        });
        getEmail();
        setOpenedDeleteModal(false);
      })
      .catch((err) => {
        showNotification({
          message: err.response.data.message,
          color: "red",
        });
        setOpenedDeleteModal(false);
      });
  };

  const editEmail = (data: any) => {
    HttpService.put(API_CONFIG.path.addEmail, {
      email: data.editEmail,
      emailId: Id,
    })
      .then((response) => {
        showNotification({
          message: response.message,
          color: "green",
        });
        getEmail();
        setEditModal(false);
      })
      .catch((err) => {
        showNotification({
          message: err.response.data.message,
          color: "red",
        });
        setEditModal(false);
      });
  };

  const columns = [
    ...emailColumns,
    {
      dataField: "actions",
      name: "Actions",
      cell: (row: any) => (
        <>
          <div className="mr-2">
            <ActionIcon
              color="yellow"
              variant="filled"
              onClick={() => {
                setEditModal(true);
                Id = row.id;
                setEmailId(row.email);
              }}
            >
              <i title="Go to Realtor details" className="bx bxs-pencil" />
            </ActionIcon>
          </div>
          <div className="mr-2">
            <ActionIcon
              variant="filled"
              onClick={() => {
                setOpenedDeleteModal(true);
                Id = row.id;
              }}
            >
              <i
                className="bx bxs-trash"
                title={"Delete Realtor"}
                style={{ fontSize: "19px" }}
              />
            </ActionIcon>
          </div>
        </>
      ),
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: "#374b6a",
        secondary: "#EDBE59",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#fff",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EDBE59",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );

  return (
    <div className="email-tab-wrapper">
      <div className="email-tab-tittle-div">
        <div className="email-note">
          <p>
            This is a list of all users that will get Email when any contact
            request is sent via portal for a property Enquiry
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex align__items--center"
        >
          <input
            className={`email-input ${errors.email?.type && "error-border"}`}
            placeholder="example@email.com"
            {...register("email", {
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            })}
          />
          <div className="add-email-btn">
            <Button
              type="submit"
              leftIcon={!addBtnLoader && <i className="bx bx-plus" />}
              //   onClick={handelAllFilter}
            >
              {addBtnLoader ? <Loader color="white " size="sm" /> : "Add Email"}
            </Button>
          </div>
        </form>
      </div>
      <div className="email-data-table-wrapper">
        <DataTable
          columns={columns}
          data={emailList}
          customStyles={customStyles}
          theme="solarized"
          pagination
          progressPending={isLoading}
          className="email-data-table"
          progressComponent={
            <Loader
              color="yellow"
              size="xl"
              className="mt-5 mb-5"
              variant="bars"
            />
          }
        />
      </div>
      <Modal
        opened={openedDeleteModal}
        onClose={() => setOpenedDeleteModal(false)}
        withCloseButton={false}
        centered
      >
        <h5>Are you sure you want to Delete this Email? </h5>
        <div className="d-flex justify-content-center mt-4">
          <Button color="yellow" className="mr-2" onClick={deleteEmail}>
            Yes
          </Button>
          <Button color="yellow" onClick={() => setOpenedDeleteModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        opened={editModal}
        onClose={() => setEditModal(false)}
        withCloseButton={false}
        centered
        className="edit-email-popup"
      >
        <h5>Are you sure you want to Edit this Email?</h5>
        <div className="d-flex justify-content-center mt-4">
          <div className="email-tab-wrapper flex align__items--center">
            <form
              onSubmit={handleSubmit(editEmail)}
              className="flex align__items--center"
            >
              <input
                className={`email-input ${
                  errors.email?.type && "error-border"
                }`}
                placeholder="example@email.com"
                {...register("editEmail", {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
              />
              <div className="add-email-btn">
                <Button type="submit">Edit Email</Button>
              </div>
            </form>
            <Button color="yellow" onClick={() => setEditModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Receiversemails;
