import React, { useEffect, useState } from "react";
import { PageHeader } from "shared/components/pageHeader";
import DataTable, { createTheme } from "react-data-table-component";
import { realtorColumns } from "./data";
import HttpService from "shared/services/http.service";
import { API_CONFIG } from "shared/services/api";
import {
  compareDate,
  customStyles,
  getDateWithTime,
} from "shared/util/tableConfig";
import { ActionIcon, Button, Loader, Modal, Switch } from "@mantine/core";
import { showNotification } from "@mantine/notifications";

let deleteId = "";
let realtorId = "";

export const RealtorListing: React.FC = () => {
  const [isLoading, setisLoading] = useState(true);
  const [realtorList, setRealtorList] = useState([]);
  const [opened, setOpened] = useState(false);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    getRealtorList();
  }, []);

  const getRealtorList = async () => {
    HttpService.get(API_CONFIG.path.realtorList, {}).then((response) => {
      const data = response.data;
      const list: any = [];

      data.forEach((prop: any) => {
        const obj = {
          id: prop.id,
          agent_id: prop.agent_id ? parseInt(prop.agent_id) : null,
          contact_person_name: prop.contact_person_name || "-",
          phone: prop.phone || "-",
          provider_company_name: prop.provider_company_name || "-",
          domain: prop.domain || "-",
          createddate: getDateWithTime(prop.createddate),
          updateddate: prop.updateddate
            ? compareDate(prop.createdDate, prop.updateddate)
            : "-",
          website_url: prop.website_url || "-",
          adr_city: prop.adr_city || "-",
          active: prop.active ? "Enabled" : "Disabled",
          active_offer: prop.active_offer || "-",
          total_offer: prop.total_offer || "-",
          is_info_complited: prop.is_info_complited ? "Yes" : "No",
        };
        list.push(obj);
      });
      setRealtorList(list);
      setisLoading(false);
    });
  };

  const toggleModal = (id: string, active: string) => {
    if (active === "Enabled") {
      setshowModal(true);
      realtorId = id;
    } else {
      handleRealtorStatusChange(id, false);
    }
  };

  const handleRealtorStatusChange = (id: string, active: boolean) => {
    setisLoading(true);

    HttpService.put(`v1/realtors/${id}/${!active}`, {})
      .then(() => {
        showNotification({
          message: "Realtor active status updated successfully.",
          color: "green",
        });
        getRealtorList();
      })
      .catch(() => {
        setisLoading(false);
        showNotification({
          message: "Something went wrong. Pleasde try again later.",
          color: "red",
        });
      });
  };

  const deleteRealtor = () => {
    setisLoading(true);

    HttpService.deleteRequest(`v1/realtors/${deleteId}`, {})
      .then(() => {
        showNotification({
          message: "Realtor deleted successfully.",
          color: "green",
        });
        setOpened(false);
        getRealtorList();
      })
      .catch(() => {
        setOpened(false);
        showNotification({
          message: "Something went wrong. Pleasde try again later.",
          color: "red",
        });
      });
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#374b6a",
        secondary: "#EDBE59",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#fff",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EDBE59",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );

  const columns = [
    ...realtorColumns,
    {
      dataField: "actions",
      name: "Actions",
      cell: (row: any) => (
        <>
          <Switch
            size="xs"
            color="green"
            className="mr-2"
            checked={row.active === "Enabled"}
            onClick={() => toggleModal(row.id, row.active)}
          />
          <ActionIcon color="yellow" variant="filled" className="mr-2">
            <i
              title="Go to Realtor details"
              className="bx bxs-pencil"
              onClick={() => window.open(`/realtor/${row.id}`, "_blank")}
            />
          </ActionIcon>
          <ActionIcon variant="filled" className="mr-2">
            <i
              className="bx bxs-trash"
              title={"Delete Realtor"}
              style={{ fontSize: "19px" }}
              onClick={() => {
                setOpened(true);
                deleteId = row.id;
              }}
            />
          </ActionIcon>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader title="Realtor Management" />
        <Button
          color="yellow"
          onClick={() => window.open(`/realtor/new`, "_blank")}
        >
          Add New Realtor
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={realtorList}
        customStyles={customStyles}
        theme="solarized"
        pagination
        progressPending={isLoading}
        progressComponent={
          <Loader
            color="yellow"
            size="xl"
            className="mt-5 mb-5"
            variant="bars"
          />
        }
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        centered
      >
        <h5>Are you sure you want to Delete this realtor? </h5>
        <div className="d-flex justify-content-center mt-4">
          <Button color="yellow" className="mr-2" onClick={deleteRealtor}>
            Yes
          </Button>
          <Button color="yellow" onClick={() => setOpened(false)}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        opened={showModal}
        onClose={() => setshowModal(false)}
        withCloseButton={false}
        centered
      >
        <h5>Are you sure you want to Disable this realtor?</h5>
        <div className="d-flex justify-content-center mt-4">
          <Button
            color="yellow"
            className="mr-2"
            onClick={() => {
              setshowModal(false);
              handleRealtorStatusChange(realtorId, true);
            }}
          >
            Yes
          </Button>
          <Button color="yellow" onClick={() => setshowModal(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};
