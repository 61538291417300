import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';

import { Button, Loader, Modal } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { UserColumns } from './data';
import { PageHeader } from 'shared/components/pageHeader';
import { customStyles, getDateWithTime } from 'shared/util/tableConfig';

import HttpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/services/api';

import './users.scss';

const UsersComponent: React.FC = () => {
    const [opened, setOpened] = useState(false);
    const [userList, setUserList] = useState([]);
    const [userStatusID, setUserStatusId] = useState({
        userid: '',
        userstatus: '',
    });
    const [isLoading, setisLoading] = useState(true);
    const history = useHistory();
    const [searchTerm, setsearchTerm] = useState('');

    useEffect(() => {
        handalUsers();
    }, []);

    const handalUsers = () => {
        setisLoading(true);
        HttpService.get(API_CONFIG.path.users)
            .then((response) => {
                const data = response.data.data;
                const list: any = [];

                data.forEach((prop: any) => {
                    const obj = {
                        createdDate: getDateWithTime(prop.createdDate),
                        id: prop.userId || null,
                        email: prop.email || '-',
                        firstName: prop.firstName || '-',
                        lastName: prop.lastName || '-',
                        isEmailVerified: prop.isEmailVerified ? 'Yes' : 'No',
                        status: prop.status,
                    };
                    list.push(obj);
                });
                setUserList(list);
                console.log('list:', list);
                setisLoading(false);
            })
            .catch((err) => {
                console.log('err:', err);
                setisLoading(false);
            });
    };

    const handalUserStatus = (userid: any, userstatus: string) => {
        setOpened(true);
        setUserStatusId({
            userid: userid,
            userstatus: userstatus,
        });
    };
    const userStatus = () => {
        HttpService.put(`${API_CONFIG.path.userStatus}/${userStatusID.userid}`)
            .then((response) => {
                if (response) {
                    handalUsers();
                    setOpened(false);
                    showNotification({
                        message: response.message,
                        color: 'green',
                    });
                }
            })
            .catch((err) => {
                setisLoading(false);
                showNotification({
                    message: err.response.data.message,
                    color: 'red',
                });
            });
    };

    const showUserDetail = (id: any) => {
        history.push({
            pathname: '/user-detail',
            state: id,
        });
    };

    createTheme(
        'solarized',
        {
            text: {
                primary: '#374b6a',
                secondary: '#EDBE59',
            },
            background: {
                default: '#fff',
            },
            context: {
                background: '#fff',
                text: '#FFFFFF',
            },
            divider: {
                default: '#EDBE59',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        },
        'light'
    );
    const columns = [
        ...UserColumns,
        {
            dataField: 'Status',
            name: 'Status',
            cell: (row: any) => (
                <>
                    <div
                        className={`status-active 
            ${row.status === 'BLOCKED' && 'status-deactiv'}
              ${row.status === 'PENDING' && 'status-pending'}
            `}
                    >
                        <div>{row.status}</div>
                    </div>
                </>
            ),
        },
        {
            dataField: 'actions',
            name: 'Actions',
            cell: (row: any) => (
                <>
                    <div
                        className={`activation-icon  
            ${row.status === 'BLOCKED' && 'deactiv-icon'}
              ${row.status === 'PENDING' && 'pending-icon'}
            `}
                    >
                        <i
                            title={row.status === 'BLOCKED' ? 'activate the user' : 'deactivate the user'}
                            className='bx bx-power-off'
                            onClick={() => handalUserStatus(row.id, row.status)}
                        />
                    </div>
                    <div className='user-icon user-detail-icon'>
                        <i title='User Information' className='bx bx-user' onClick={() => showUserDetail(row.id)} />
                    </div>
                </>
            ),
        },
    ];

    return (
        <div>
            <PageHeader title='User Management' />
            <div className='search-bar'>
                <div className='search-bar-div'>
                    <div className='seacrch-icon'>
                        <i className='bx bx-search' />
                    </div>
                    <div>
                        <input placeholder='search' onChange={(event) => setsearchTerm(event.target.value)} />
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={userList.filter((item: any) => {
                    if (searchTerm === '') {
                        return item;
                    } else if (
                        item.firstName.toLowerCase().includes(searchTerm.replace(/\s/g, '').toLowerCase()) ||
                        item.lastName.toLowerCase().includes(searchTerm.replace(/\s/g, '').toLowerCase()) ||
                        item.email.toLowerCase().includes(searchTerm.replace(/\s/g, '').toLowerCase())
                    ) {
                        return item;
                    }
                })}
                customStyles={customStyles}
                theme='solarized'
                pagination
                progressPending={isLoading}
                progressComponent={<Loader color='yellow' size='xl' className='mt-5 mb-5' variant='bars' />}
            />
            <Modal opened={opened} onClose={() => setOpened(false)} withCloseButton={false} centered>
                {userStatusID.userstatus === 'BLOCKED' ? (
                    <h5>Are you sure you want to activate the user?</h5>
                ) : (
                    <h5>Are you sure you want to deactivate the user?</h5>
                )}
                <div className='d-flex justify-content-center mt-4'>
                    <Button color='yellow' className='mr-2' onClick={userStatus}>
                        Yes
                    </Button>
                    <Button color='yellow' onClick={() => setOpened(false)}>
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default UsersComponent;
