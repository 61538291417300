import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import Dashboard from 'components/dashboard/dashboard';
import Login from 'components/auth/login';
import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import { ObjectListing } from 'components/objects/objectListing';
import { RealtorListing } from 'components/realtor/realtorListing';
import Profile from 'components/adminprofile/profile';
import UsersComponent from "components/users/users";
import UserDetail from "components/users/userDtails.tsx/userDetail";
import RealtorDetails from "components/realtor/realtorDetails";
import ObjectDtails from "components/objects/objectDtails/objectDtails";
import Setting from "components/settings/setting";

const router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PrivateRoute path="/object" component={ObjectListing} />
      <PrivateRoute path={"/realtor/:id"} component={RealtorDetails} />
      <PrivateRoute path="/realtor" component={RealtorListing} />
      <PrivateRoute path={"/profile"} component={Profile} />
      <PrivateRoute path="/user" component={UsersComponent} />
      <PrivateRoute path="/user-detail" component={UserDetail} />
      <PrivateRoute path={"/object-detail/:id"} component={ObjectDtails} />
      <PrivateRoute path={"/settings"} component={Setting} />
      <PrivateRoute path={["/dashboard", "/"]} component={Dashboard} />
    </Switch>
  </BrowserRouter>
);

export default router;
