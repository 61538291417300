import React, { useState } from "react";
import { AppShell, Navbar, Header, Grid } from "@mantine/core";
import logo from "assets/images/logo.png";
import "./layout.scss";
import { Link, useHistory } from "react-router-dom";

export const Layout: React.FC<any> = (props) => {
  const history = useHistory();
  const [openTab, setOpenTab] = useState(history.location.pathname);
  const userProfile = () => {
    history.push("/profile");
  };
  const changeTab = (name: any) => {
    setOpenTab(name);
  };
  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar className="navbar-item" width={{ base: 300 }} p="xs">
          {menuItems.map((data, i) => (
            <Link to={data.link} key={i} onClick={() => changeTab(data.link)}>
              <div
                className={`navbar-item d-flex align-items-center  ${
                  openTab === data.link && "active-tab"
                }`}
              >
                <i className={`bx mr-2 ${data.icon}`} />
                <p>{data.label}</p>
              </div>
            </Link>
          ))}
        </Navbar>
      }
      header={
        <Header
          className="d-flex align-items-center justify-content-between"
          height={60}
          p="xs"
        >
          <img
            src={logo}
            alt="src"
            style={{ maxHeight: "35px", float: "left" }}
          />
          <Grid>
            <div
              className={"user-icon d-flex mr-3"}
              onClick={() => userProfile()}
            >
              <i className="bx bx-user-circle " />
            </div>
          </Grid>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colors.gray[0],
        },
      })}
    >
      {props.children}
    </AppShell>
  );
};

const menuItems = [
  { label: "Dashboard", icon: "bxs-dashboard", link: "/" },
  { label: "Users", icon: "bx-user", link: "/user" },
  { label: "Realtor Management", icon: "bxs-home", link: "/realtor" },
  { label: "Object Management", icon: "bx-list-ul", link: "/object" },
  { label: "Settings", icon: "bx bxs-cog", link: "/settings" },
];
