import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import DataTable, { createTheme } from "react-data-table-component";

import { Button, Loader, Modal, Select, Switch } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { ArrowIcon } from "components/icon/icon";

import { PageHeader } from "shared/components/pageHeader";
import { API_CONFIG } from "shared/services/api";
import HttpService from "shared/services/http.service";
import { customStyles } from "shared/util/tableConfig";
import { objectColumns } from "./utils/objectData";
import ObjectDtails from "./objectDtails/objectDtails";

import "./objectListing.scss";

export const ObjectListing: React.FC = () => {
  const [objectList, setObjectList] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [opened, setOpened] = useState(false);
  const [realtorId, setRealtorId] = useState({
    id: "",
    isenable: true,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [filterItem, setFilterItem] = useState({
    demandType: "",
    objectType: "",
    fromPrice: 0,
    toPrice: 0,
    postalCode: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordPerPage, setRecordPerPage] = useState(10);
  const [searchLoading, setSearchLoading] = useState({
    searchButton: false,
    resetButton: false,
  });
  const [objectDetailPoup, setObjectDetailPopup] = useState(false);
  const [objectId, setObjectId] = useState("");

  useEffect(() => {
    getObject(filterItem);
  }, [currentPage, recordPerPage]);

  const removeEmpty = (obj: any) => {
    Object.entries(obj).forEach(
      ([key, val]) =>
        (val && typeof val === "object" && removeEmpty(val)) ||
        ((val === null || val === "" || val === 0) && delete obj[key])
    );
    return obj;
  };

  const getObject = (params: any) => {
    const param: any = {
      currentPage: currentPage,
      recordPerPage: recordPerPage,
      ...filterItem,
      ...params,
    };

    setisLoading(true);
    HttpService.post(API_CONFIG.path.objects, removeEmpty(param))
      .then((response) => {
        setObjectList(response.data.data);
        setisLoading(false);
        setTotalRows(response.data.total);
        setSearchLoading({
          searchButton: false,
          resetButton: false,
        });
      })
      .catch((err) => {
        console.log(" err", err);
        setisLoading(false);
        setSearchLoading({
          searchButton: false,
          resetButton: false,
        });
      });
  };

  const handelAllFilter = () => {
    setSearchLoading({
      searchButton: true,
      resetButton: false,
    });
    getObject(filterItem);
  };

  const resetAllFilter = () => {
    setFilterItem({
      demandType: "",
      objectType: "",
      fromPrice: 0,
      toPrice: 0,
      postalCode: 0,
    });
    getObject({
      demandType: "",
      objectType: "",
      fromPrice: 0,
      toPrice: 0,
      postalCode: 0,
    });
    setSearchLoading({
      searchButton: false,
      resetButton: true,
    });
  };

  const handelsearch = (value: any, key: string) => {
    if (key === "fromPrice" || key === "toPrice") {
      setFilterItem({
        ...filterItem,
        [key]: parseFloat(value),
      });
    } else {
      setFilterItem({
        ...filterItem,
        [key]: value,
      });
    }
  };

  const handelInputFilter = (event: any, name: string) => {
    const value = event.target.value;

    setFilterItem({
      ...filterItem,
      [name]: parseFloat(value),
    });
  };

  const toggleModal = (id: string, isEnable: boolean) => {
    setRealtorId({
      id: id,
      isenable: isEnable,
    });
    setOpened(true);
  };

  const handleRealtorStatusChange = () => {
    setisLoading(true);
    HttpService.put(`v1/objects/changestatus/${realtorId.id}`, {})
      .then(() => {
        showNotification({
          message: "Realtor active status updated successfully.",
          color: "green",
        });
        getObject(filterItem);
        setOpened(false);
      })
      .catch(() => {
        setisLoading(false);
        showNotification({
          message: "Something went wrong. Pleasde try again later.",
          color: "red",
        });
      });
  };

  const objectDetails = (id: any) => {
    setObjectDetailPopup(!objectDetailPoup);
    setObjectId(id);
  };

  const closePopup = () => {
    setObjectDetailPopup(false);
  };

  createTheme(
    "solarized",
    {
      text: {
        primary: "#374b6a",
        secondary: "#EDBE59",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#fff",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EDBE59",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );

  const columns = [
    ...objectColumns,
    {
      dataField: "actions",
      name: "Actions",
      cell: (row: any) => (
        <>
          <div>
            <Switch
              size="xs"
              color="green"
              className="mr-2 "
              checked={row.isEnable}
              onClick={() => toggleModal(row.id, row.isEnable)}
            />
          </div>
          <div onClick={() => objectDetails(row.id)} className="pointer">
            <i className="bx bx-link-external external-link-icon" />
          </div>
        </>
      ),
    },
  ];

  const Pagination = (page: any) => {
    setCurrentPage(page);
  };

  const ChangeRow = async (newPerPage: any) => {
    setRecordPerPage(newPerPage);
    // getObject();
  };

  return (
    <div>
      <PageHeader title="Object Management" />
      <div className="filter-section flex">
        <div className="filter-div">
          <Select
            label="Demand"
            placeholder="--select Type--"
            data={[
              { value: "Buy", label: "Buy" },
              { value: "Rent", label: "Rent" },
            ]}
            className={"demandType-filter"}
            value={filterItem.demandType}
            clearable
            onChange={(value: string) => handelsearch(value, "demandType")}
          />
        </div>
        <div className="filter-div">
          <Select
            label="Object"
            placeholder="--select Type--"
            data={[
              { value: "Apartment", label: "Apartment" },
              { value: "House", label: "House" },
            ]}
            className={"demandType-filter"}
            value={filterItem.objectType}
            clearable
            onChange={(value: string) => handelsearch(value, "objectType")}
          />
        </div>
        <div className="filter-div">
          <label className="price-filter-label">Price</label>
          <div className="flex align__items--center ">
            <CurrencyInput
              name="fromPrice"
              placeholder="Min Price"
              prefix="€"
              decimalsLimit={5}
              value={filterItem.fromPrice || ""}
              onValueChange={(value, name: any) => handelsearch(value, name)}
              className={"price-filter mr-1"}
            />
            <ArrowIcon height="24px" width="24px" />
            <CurrencyInput
              name="toPrice"
              placeholder="Max Price"
              value={filterItem.toPrice || ""}
              prefix="€"
              decimalsLimit={5}
              onValueChange={(value, name: any) => handelsearch(value, name)}
              className={"price-filter ml-1"}
            />
          </div>
        </div>
        <div className="filter-div">
          <label className="price-filter-label">Postcode</label>
          <input
            type="number"
            name="postalCode"
            placeholder="postalCode"
            value={filterItem.postalCode || ""}
            className="price-filter postalCode-filter"
            onChange={(event) => handelInputFilter(event, "postalCode")}
          />
        </div>
      </div>
      <div className="flex align__items--center">
        <div className="search-filter-btn mr-4">
          <Button
            leftIcon={
              !searchLoading.searchButton && (
                <i className="bx bx-search-alt-2" />
              )
            }
            onClick={handelAllFilter}
          >
            {searchLoading.searchButton ? (
              <Loader color="white " size="sm" />
            ) : (
              "Search"
            )}
          </Button>
        </div>
        <div className=" search-filter-btn  reset-filter-btn">
          <Button
            leftIcon={
              !searchLoading.resetButton && <i className="bx bx-reset" />
            }
            onClick={resetAllFilter}
          >
            {searchLoading.resetButton ? (
              <Loader color="white " size="sm" />
            ) : (
              "Reset the filter"
            )}
          </Button>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={objectList}
        customStyles={customStyles}
        theme="solarized"
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={ChangeRow}
        onChangePage={(page) => Pagination(page)}
        progressPending={isLoading}
        progressComponent={
          <Loader
            color="yellow"
            size="xl"
            className="mt-5 mb-5"
            variant="bars"
          />
        }
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        centered
      >
        {realtorId.isenable ? (
          <h5>Are you sure you want to deactivate the user?</h5>
        ) : (
          <h5>Are you sure you want to activate the user?</h5>
        )}
        <div className="d-flex justify-content-center mt-4">
          <Button
            color="yellow"
            className="mr-2"
            onClick={handleRealtorStatusChange}
          >
            Yes
          </Button>
          <Button color="yellow" onClick={() => setOpened(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
      {objectDetailPoup && (
        <div className="object-detail-wrapper">
          <div className="object-detail-div">
            <ObjectDtails closePopup={closePopup} objectId={objectId} />
          </div>
        </div>
      )}
    </div>
  );
};
export const test = {
  rowsPerPage: 10,
  rowCount: 10,
  currentPage: 3,
};
