import queryString from "query-string";
import { BASE_URL } from "./baseUrl";

export const API_CONFIG = {
  // eslint-disable-next-line no-undef
  baseUrl: `${BASE_URL}`,
  path: {
    // v1 APIs
    login: "v1/auth/login",
    logout: "v1/logout",
    changePassword: "v1/profile/password/change",
    realtorList: "v1/realtors",
    users: "v1/users?currentPage=1&recordPerPage=100",
    userStatus: "v1/users/status",
    userDetail: "v1/users/details",
    userProfile: "v1/profile",
    objects: "v1/objects",
    dashboard: "v1/dashboard",
    emailList: "v1/emails/list",
    addEmail: "v1/emails",
    editEmail: "v1/emails",
    // v4 APIs
    objectDetails: "v4/objects/details",
  },
};

export const getUrl = (url: string, params: any = {}): string => {
    Object.keys(params).forEach(
        (key) => (params[key] == null || params[key] === "") && delete params[key]
    );
    if (url.includes("http")) {
        return url;
    }
    let urlString = `${BASE_URL}${url}`;
    if (params && params !== "" && Object.keys(params).length > 0) {
        urlString += `?${queryString.stringify(params)}`;
    }
    return urlString;
};
