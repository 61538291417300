/* eslint-disable @typescript-eslint/ban-ts-comment */
import { applyMiddleware, createStore, compose } from "redux";
import promise from "redux-promise-middleware";

import thunk from "redux-thunk";
import rootReducer from "reducers/rootReducer";

const isProdcution = false;

const composeEnhancers =
  //@ts-ignore
  !isProdcution &&
  typeof window === "object" &&
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE
    ? //@ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const middleware = applyMiddleware(thunk, promise);

const enhancer = composeEnhancers(middleware);

export function configureStore(initialState: any = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  return store;
}

export const store = configureStore();
