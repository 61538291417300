import { getDateWithTime } from 'shared/util/tableConfig';

export const objectColumns = [
    {
        name: 'Id',
        selector: (row: any) => (row.platforms[0] ? row.platforms[0]?.offer_id : '-'),
        sort: true,
        sortable: true,
    },
    {
        name: 'City',
        selector: (row: any) => row.city || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'Quarter',
        selector: (row: any) => row.quarter || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'Object Type',
        selector: (row: any) => row.objectType || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'Postcode',
        selector: (row: any) => row.postalCode || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'State',
        // selector: (row: any) => (row.status ? "Active" : "Disabled"),
        cell: (row: any) => (
            <>
                <div
                    className={`status-active 
            ${row.isEnable ? ' ' : 'status-deactiv'}
              
            `}
                >
                    <div>{row.isEnable ? 'Active' : 'Disabled'}</div>
                </div>
            </>
        ),
        sort: true,
        sortable: true,
    },
    {
        name: 'Price (in EUR)',
        selector: (row: any) => row.price || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'Realtor Company',
        selector: (row: any) => (row.realtor ? row.realtor.provider_company_name : '-'),
        sort: true,
        sortable: true,
    },
    {
        name: 'Listed At',
        selector: (row: any) => getDateWithTime(row.createdAt) || '-',
        sort: true,
        sortable: true,
    },
    {
        name: 'Updated At',
        selector: (row: any) => getDateWithTime(row.updatedAt) || '-',
        sort: true,
        sortable: true,
    },
];

export const objectTableData = (objectData: any) => {
    const objectDetails = {
        generalDetails: [
            { ID: objectData.id || '-' },
            { Title: objectData.ad_title || '-' },
            { 'Original Id': objectData.ad_platforms[0].platform_id || '-' },
            { 'Listed At': getDateWithTime(objectData.created_at) || '-' },
            { 'Updated At': getDateWithTime(objectData.updated_at) || '-' },
            { Type: objectData.object_type || '-' },
            { 'Sub type': objectData.object_type_sub || '-' },
        ],
        locationDetails: [
            { City: objectData.adr_city || '-' },
            { Street: objectData.adr_raw || '-' },
            { 'Postal Code': objectData.adr_postal_code || '-' },
            { Quarter: objectData.adr_quarter || '-' },
            { Latitude: objectData.adr_coord_lat_epsg4326 || '-' },
            { Longitude: objectData.adr_coord_lon_epsg4326 || '-' },
        ],
        Segmentation: [{ 'Demand type': 'N/A' }, { 'Object type': objectData.object_type || '-' }],
        OfferDetails: [
            { 'Platform ID': objectData.ad_platforms[0].platform_id || '-' },
            { 'Object Title': objectData.ad_title || '-' },
            { Price: objectData.ad_price || '-' },
            { 'Price Currency': objectData.ad_price_currency || '-' },
            { Costs: objectData.costs || '-' },
            { 'Costs per m2': objectData.costs_m2 || '-' },
            { 'Ad price Ratio': objectData.ad_price_ratio || '-' },
            { 'Costs market value': objectData.costs_market_value || '-' },
        ],
        AreaInformation: [
            { 'Living Area': objectData.area_living || '-' },
            { 'Usable Area': objectData.area_use || '-' },
            { 'Plot Area': objectData.area_plot || '-' },
        ],
        Equipment: [
            { 'Guest Toilet': objectData.equip_bath_guest_toilet ? 'Yes' : 'No' },
            { 'Kitched fitted': objectData.equip_kitchen ? 'Yes' : 'No' },
        ],
        ObjectFeatures: [
            { Rooms: objectData.prop_rooms_amount || '-' },
            { 'Property Type': 'N/A' },
            { 'Costruction Year': objectData.prop_construction_year || '-' },
            { 'Newly constructed': objectData.prop_building_new ? 'Yes' : 'No' },
            { 'Contains floorplan': objectData.prop_floor_act || 'N/A' },
            { 'Parking Space': objectData.parking ? 'Yes' : 'No' },
            { Balcony: objectData.equip_balcony ? 'Yes' : 'No' },
            { Garden: objectData.equip_garden ? 'Yes' : 'No' },
            { Terrace: objectData.equip_terrace ? 'Yes' : 'No' },
            { 'Energy Certificate': objectData.energy_certificate ? 'Yes' : 'No' },
            {
                'Energy Efficiency Class': objectData.energy_efficiency_class ? 'Yes' : 'No',
            },
            { 'Heating Type': objectData.obj_heatingtype || '-' },
        ],
        ProviderDetails: [
            { 'Provider Company': objectData.provider_company_name || '-' },
            { 'Provider Email Address': objectData.provider_email_address || '-' },
        ],
    };
    return [
        {
            tittleName: 'General Details',
            coldata: objectDetails.generalDetails,
        },
        {
            tittleName: 'Adress and Location',
            coldata: objectDetails.locationDetails,
        },
        {
            tittleName: 'Segmentation',
            coldata: objectDetails.Segmentation,
        },
        {
            tittleName: 'Offer Information',
            coldata: objectDetails.OfferDetails,
        },
        {
            tittleName: 'Area Information',
            coldata: objectDetails.AreaInformation,
        },
        {
            tittleName: 'Equipment',
            coldata: objectDetails.Equipment,
        },
        {
            tittleName: 'Object Features',
            coldata: objectDetails.ObjectFeatures,
        },
        {
            tittleName: 'Provider Details',
            coldata: objectDetails.ProviderDetails,
        },
    ];
};
