import { useEffect, useState } from 'react';
import { Loader } from '@mantine/core';

import { CloseIcon } from 'components/icon/icon';
import { PageHeader } from 'shared/components/pageHeader';
import ImageGallary from './ImageGallary';

import HttpService from 'shared/services/http.service';
import DataTable, { createTheme } from 'react-data-table-component';
import { customStyles } from 'shared/util/tableConfig';
import { Link, useHistory } from 'react-router-dom';
import { objectTableData } from '../utils/objectData';
import { ENV } from 'shared/services/baseUrl';

interface ObjectDtailsProps {
    closePopup: () => void;
    objectId: string;
}

const ObjectDtails = (props: ObjectDtailsProps) => {
    const [objectImage, setObjectImage] = useState<any>();
    const [isLoading, setisLoading] = useState(true);
    const history = useHistory();
    const [tableData, setTableData] = useState<any>([]);

    const urlId = history.location.pathname.split('/')[2];
    const tabName = history.location.pathname.split('/')[1];

    useEffect(() => {
        getObjectData();
    }, []);

    const getObjectData = () => {
        HttpService.get(`v1/objects/details/${props.objectId || urlId}`, {})
            .then((response) => {
                const objectData = response.data;
                setObjectImage(response.data.ad_pictures_urls);
                setTableData(objectTableData(objectData));
                setisLoading(false);
            })
            .catch(() => {
                setisLoading(true);
            });
    };

    createTheme(
        'solarized',
        {
            text: {
                primary: '#374b6a',
                secondary: '#EDBE59',
            },
            background: {
                default: '#fff',
            },
            context: {
                background: '#fff',
                text: '#FFFFFF',
            },
            divider: {
                default: '#EDBE59',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        },
        'light'
    );

    const columns = [
        {
            cell: (col: any) => (
                <>
                    <div>
                        <div>{Object.getOwnPropertyNames(col)}</div>
                    </div>
                </>
            ),
        },
        {
            cell: (col: any) => (
                <>
                    <div>
                        <div>{Object.values(col)}</div>
                    </div>
                </>
            ),
        },
    ];

    return (
        <div className='object-detail-section'>
            {tabName === 'object' && (
                <div className='close-btn-wrapper'>
                    <div className='close-btn flex align__items--center'>
                        <div className=' mr-2'>
                            <Link to={`/object-detail/${props.objectId}`} target='_blank' rel='noopener noreferrer'>
                                Open link in new tab
                                <i className='bx bx-link' />
                            </Link>
                        </div>
                        <div onClick={props.closePopup} className='pointer'>
                            <CloseIcon width='26px' height='26px' />
                        </div>
                    </div>
                </div>
            )}
            <div className='detail-section'>
                <div className='flex align__items--center justify__content--between'>
                    <PageHeader title='Objects Details' />
                    <div>
                        <a
                            href={`https://${ENV === 'PROD' ? 'immoweb.de' : 'staging.immoweb.de'}/objekt/${props.objectId || urlId}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Go to Site Detail Page
                            <i className='bx bx-link' />
                        </a>
                    </div>
                </div>
                <div className='object-gallery'>
                    <h3 className='gallery-tittle'>Picture Gallery</h3>
                    {objectImage && <ImageGallary imgList={objectImage} startIndex={10} />}
                </div>
                {tableData.map((item: any, index: any) => (
                    <div className='user-detail-wrapper object-detail-table' key={index}>
                        <DataTable
                            title={item.tittleName}
                            columns={columns}
                            data={item.coldata as any}
                            customStyles={customStyles}
                            theme='solarized'
                            progressPending={isLoading}
                            className={'userdetail-table'}
                            progressComponent={<Loader color='yellow' size='xl' className='mt-5 mb-5' variant='bars' />}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ObjectDtails;
