import { ActionIcon, Loader } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";

export const PageHeader: React.FC<{
  title: string;
  isLoading?: boolean;
  backLink?: string;
}> = (props) => {
  const { title, isLoading, backLink } = props;

  return (
    <div className="d-flex align-items-center">
      {backLink && backLink !== "" && (
        <Link to={backLink} className="mr-2">
          <ActionIcon color="yellow" size="lg" radius="md" variant="filled">
            <i className="bx bxs-chevron-left" />
          </ActionIcon>
        </Link>
      )}
      <h4 className="page-title mb-4">{title}</h4>
      {isLoading && (
        <Loader size="sm" color="yellow" className="ml-2" variant="bars" />
      )}
    </div>
  );
};
