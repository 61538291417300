export const realtorColumns = [
  {
    name: "Agent Id",
    selector: (row: any) => row.agent_id,
    sort: true,
    sortable: true,
  },
  {
    selector: (row: any) => row.provider_company_name,
    name: "Company Name",
    sort: true,
    sortable: true,
  },
  {
    selector: (row: any) => row.total_offer,
    name: "Total Offers",
    sort: true,
    sortable: true,
  },
  {
    selector: (row: any) => row.active_offer,
    name: "Active Offers",
    sort: true,
    sortable: true,
  },
  {
    selector: (row: any) => row.createddate,
    name: "Date",
    sort: true,
    sortable: true,
  },

  {
    selector: (row: any) => row.active,
    dataField: "active",
    name: "Enabled Status",
    sort: true,
  },
 
];
