import React, { Suspense } from "react";
import { Redirect, Route } from "react-router-dom";
import { getAuthToken } from "shared/util/utility";
import { Layout } from "components/layout/layout";

interface Props {
  path: string | string[];
  component: any;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    exact
    render={(props) =>
      getAuthToken() ? (
        <>
          <>
            <Suspense fallback={<div style={{ padding: "20% 0" }}>test</div>}>
              <Layout>
                <Component {...props} />
              </Layout>
            </Suspense>
          </>
        </>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;
