import moment from 'moment';

export const headerStyle = () => ({
	textAlign: 'center',
	verticalAlign: 'top',
});

export const getDate = (dateString: string) =>
	moment(dateString).format('YYYY-MM-DD');

export const getDateWithTime = (dateString: string) =>
	moment(dateString).format('YYYY-MM-DD HH:mm:ss');

export const getUTCDate = (dateString: string) =>
	moment.utc(dateString).format('YYYY-MM-DD');

export const compareDate = (createdAt: string, updatedAt: string) => {
	const a = new Date(createdAt);
	const b = new Date(updatedAt);
	if (a.getTime() === b.getTime()) {
		return '-';
	}
	return getDateWithTime(updatedAt);
};



export const customSort = (order: string) => {
	if (!order) return <span>&nbsp;&nbsp;↑↓</span>;
	else if (!order || order === 'asc')
		return (
			<span>
				&nbsp;&nbsp;↑
				<span color='red'>↓</span>
			</span>
		);
	else if (order === 'desc')
		return (
			<span>
				&nbsp;&nbsp;
				<span color='red'>↑</span>↓
			</span>
		);
	return null;
};

export const customTotal = (from: number, to: number, size: number) =>
	size > 0 && (
		<span className='react-bootstrap-table-pagination-total'>
			Showing {from} to {to} of {size} Results
		</span>
	);

const sizePerPageList = [
	{
		text: '10',
		value: 10,
	},
	{
		text: '20',
		value: 20,
	},
	{
		text: '50',
		value: 50,
	},
	{
		text: '100',
		value: 100,
	},
];

export const paginationOptions = {
	sizePerPageList: sizePerPageList,
	alwaysShowAllBtns: true,
	prePageText: 'Previous',
	nextPageText: 'Next',
	withFirstAndLast: true,
	showTotal: true,
	paginationTotalRenderer: customTotal,
};

export const customStyles = {
	rows: {
		style: {
			minHeight: '50px', // override the row height
		},
	},
	headCells: {
		style: {
			paddingLeft: '8px', // override the cell padding for head cells
			paddingRight: '8px',
			fontSize: '16px',
			fontWeight: 'bold',
		},
	},
	cells: {
		style: {
			paddingLeft: '8px', // override the cell padding for data cells
			paddingRight: '8px',
			fontSize: '14px',
		},
	},
};
