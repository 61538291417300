import React from "react";

import { PageHeader } from "shared/components/pageHeader";

import "../settings/setting.scss";
import Receiversemails from "./components/receiversemails";

const Setting: React.FC = () => (
  <div className="setting-section">
    <PageHeader title="Admin Settings" />
    <div className="page-wrapper">
      <div className="page-body">
        <div className="card">
          <div className="card-block">
            <div className="nav-tabs-wrapper">
              <ul className="nav nav-tabs">
                <li className="nav-item active-tab">Receivers Emails</li>
              </ul>
            </div>
            <div>
              <Receiversemails />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Setting;
