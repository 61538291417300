import React, { Suspense } from "react";
import { Route } from "react-router-dom";

interface Props {
  path: string | string[];
  component: any;
}

const PublicRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    exact
    render={(props) => (
      <Suspense
        fallback={
          <div>
            <div style={{ padding: "20% 0" }}>test</div>
          </div>
        }
      >
        <Component {...props} />
      </Suspense>
    )}
  />
);

export default PublicRoute;
