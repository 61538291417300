import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";

import tipTile from "../../../assets/images/placeholder.png";
import "react-image-gallery/styles/css/image-gallery.css";

interface IImageGalleryProps {
  imgList: any;
  startIndex: number;
}

const ImageGallary = (props: IImageGalleryProps) => {
  const [imageList, setImageList] = useState<any[]>([]);

  const setPlaceholderImg = (e: any) => {
    e.target.src = tipTile;
  };
  useEffect(() => {
    if (imageList.length === 0) {
      const list: any = [];
      props.imgList.forEach((item: any) => {
        list.push({
          original: item,
          thumbnail: item,
        });
      });
      setImageList(list);
    }
  }, [imageList.length, props.imgList]);

  return (
    <div
      className={`image-gallery-popup carousel ${
        imageList && imageList.length === 1 && "one-image-slider"
      }`}
    >
      <ImageGallery
        showBullets={false}
        items={imageList}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={true}
        // startIndex={props.startIndex}
        onImageError={setPlaceholderImg}
        onThumbnailError={setPlaceholderImg}
      />
    </div>
  );
};

export default ImageGallary;
