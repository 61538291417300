import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useHistory } from "react-router-dom";
import "../users.scss";

import { Button, Grid, Loader, Modal, Text } from "@mantine/core";

import { PageHeader } from "shared/components/pageHeader";
import { API_CONFIG } from "shared/services/api";
import HttpService from "shared/services/http.service";
import { customStyles, getDateWithTime } from "shared/util/tableConfig";

import { showNotification } from "@mantine/notifications";

const UserDetail: React.FC = () => {
  const history = useHistory();
  const UserId = history.location.state;
  const [userDetail, setUserDetail] = useState([
    { "User Id": "" },
    { Email: "" },
    { "Role Name": "" },
    { Status: "" },
    { "First Name": "" },
    { "Last Name": "" },
    { Avatar: "" },
    { "is Email Verified": "" },
    { "Created Date": "" },
    { Language: "" },
    { Phone: "" },
    { Address: "" },
  ]);
  const [isLoading, setisLoading] = useState(true);
  const [opened, setOpened] = useState(false);

  const [userShorDetail, setUserShortDetail] = useState({
    firstName: "",
    lastName: "",
    CreatedDate: "",
    status: "",
    email: "",
    avatar: "",
    EmailVerified: "",
  });

  const handelBack = () => {
    history.push("/user");
  };
  useEffect(() => {
    getUserDetail();
  }, []);
  const getUserDetail = () => {
    setisLoading(true);
    HttpService.get(`${API_CONFIG.path.userDetail}/${UserId}`)
      .then((response) => {
        const Detail = response.data.details;

        setUserDetail([
          { "User Id": Detail.userId || null },
          { Email: Detail.email || "-" },
          { "Role Name": Detail.roleName || "-" },
          { Status: Detail.status },
          { "First Name": Detail.firstName || "-" },
          { "Last Name": Detail.lastName || "-" },
          { Avatar: Detail.avatar },
          {
            "is Email Verified": Detail.isEmailVerified ? "Yes" : "No",
          },
          { "Created Date": getDateWithTime(Detail.createdDate) || "-" },
          { Phone: Detail.phone || "-" },
          { Address: Detail.address || "-" },
        ]);
        setUserShortDetail({
          firstName: Detail.firstName || "-",
          lastName: Detail.lastName || "-",
          CreatedDate: getDateWithTime(Detail.createdDate) || "-",
          status: Detail.status,
          email: Detail.email || "-",
          avatar: Detail.avatar,
          EmailVerified: Detail.isEmailVerified ? "Yes" : "No",
        });
        setisLoading(false);
      })
      .catch((err) => {
        console.log(" err:", err);
        setisLoading(false);
      });
  };

  const handelStatus = () => {
    HttpService.put(`${API_CONFIG.path.userStatus}/${UserId}`)
      .then((response) => {
        if (response) {
          getUserDetail();
          setOpened(false);
          showNotification({
            message: response.message,
            color: "green",
          });
        }
      })
      .catch((err) => {
        setisLoading(false);
        setOpened(false);

        showNotification({
          message: err.response.data.message,
          color: "red",
        });
      });
  };

  const columns = [
    {
      name: "User Detail",
      cell: (col: any) => (
        <>
          <div>
            <div>{Object.getOwnPropertyNames(col)}</div>
          </div>
        </>
      ),
    },
    {
      cell: (col: any) => {
        const colName = Object.getOwnPropertyNames(col).toString();
        return (
          <>
            <div>
              {colName === "Avatar" ? (
                <div className="user-image">
                  {col.Avatar ? (
                    <img src={col.Avatar} />
                  ) : (
                    <i className="bx bx-user-circle " />
                  )}
                </div>
              ) : (
                <div>{Object.values(col)}</div>
              )}
            </div>
          </>
        );
      },
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: "#374b6a",
        secondary: "#EDBE59",
      },
      background: {
        default: "#fff",
      },
      context: {
        background: "#fff",
        text: "#FFFFFF",
      },
      divider: {
        default: "#EDBE59",
      },
      action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
      },
    },
    "light"
  );
  const admindata = {
    "Full Name": `${userShorDetail.firstName}${" "}${userShorDetail.lastName}`,
    "Registration Date": userShorDetail.CreatedDate,
    Email: userShorDetail.email,
    EmailVerified: userShorDetail.EmailVerified,
  };
  return (
    <div className="user-detail-wrapper">
      <div className="flex ">
        <div className=" page-title back-icon-div " onClick={handelBack}>
          <i className="bx bx-arrow-back back-icon" />
        </div>
        <PageHeader
          title={`${userShorDetail.firstName}${" "}${userShorDetail.lastName}`}
        />
      </div>
      <div className="profile-info d-flex align-items-center justify-content-between">
        <Grid className="w-100">
          <Grid.Col span={2} className="admin-data-icon ">
            <div className="user-image">
              {userShorDetail.avatar ? (
                <img src={userShorDetail.avatar} />
              ) : (
                <i className="bx bx-user-circle " />
              )}
            </div>
          </Grid.Col>
          <Grid.Col span={8} className="admin-data">
            {" "}
            {Object.entries(admindata).map(([key, value], index) => (
              <Text key={index} className="admin-data-wrapper">
                {key} : <span className="admin-data-value"> {value}</span>
              </Text>
            ))}
          </Grid.Col>
          <Grid.Col span={2} className="admin-data">
            <div className="user-status-div">
              <button
                onClick={() => setOpened(true)}
                className="user-status-btn"
              >
                {userShorDetail.status === "BLOCKED"
                  ? "Activate the user"
                  : "Deactivate the user"}
              </button>
            </div>
          </Grid.Col>
        </Grid>
      </div>
      <DataTable
        // title={"User Data"}
        columns={columns}
        data={userDetail}
        customStyles={customStyles}
        theme="solarized"
        progressPending={isLoading}
        className={"userdetail-table"}
        progressComponent={
          <Loader
            color="yellow"
            size="xl"
            className="mt-5 mb-5"
            variant="bars"
          />
        }
      />
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        withCloseButton={false}
        centered
      >
        {userShorDetail.status === "BLOCKED" ? (
          <h5>Are you sure you want to activate the user?</h5>
        ) : (
          <h5>Are you sure you want to deactivate the user?</h5>
        )}
        <div className="d-flex justify-content-center mt-4">
          <Button color="yellow" className="mr-2" onClick={handelStatus}>
            Yes
          </Button>
          <Button color="yellow" onClick={() => setOpened(false)}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UserDetail;
