import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import "./dashboard.scss";
import { PageHeader } from "shared/components/pageHeader";
import HttpService from "shared/services/http.service";
import { API_CONFIG } from "shared/services/api";

const Dashboard: React.FC = () => {
  const [dashBoardList, setDashBoardList] = useState({
    userCount: "",
    objectCount: "",
    adminCount: "",
  });

  useEffect(() => {
    getdashbordData();
  }, []);

  const getdashbordData = () => {
    HttpService.get(API_CONFIG.path.dashboard)
      .then((response) => {
        const data = {
          userCount: response.data.userCount,
          objectCount: response.data.objectCount,
          adminCount: response.data.adminCount,
        };
        setDashBoardList(data);
      })
      .catch((err) => {
        console.log(" err:", err);
      });
  };

  const dashboardData = [
    {
      name: "Users",
      value: dashBoardList.userCount,
      icon: " bxs-user-detail",
    },
    {
      name: "Objects",
      value: dashBoardList.objectCount,
      icon: " bx-list-ul",
    },
    {
      name: "Admins",
      value: dashBoardList.adminCount,
      icon: "bxs-user-circle",
    },
  ];

  return (
    <div>
      <PageHeader title="Dashboard" />
      <div className="dash-bord-detail-wrapper">
        {dashboardData.map((data, i) => (
          <div className="dashboard-info " key={i}>
            <div className="dashboard-detail-div">
              <h6>{data.name}</h6>
              <h3>
                <CountUp
                  start={0}
                  end={data.value as any}
                  className="detail-count"
                />
              </h3>
            </div>
            <i className={`bx ${data.icon} dashboard-icon`} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Dashboard;
