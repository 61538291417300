export const realtorDetailfields = [
  { name: "agent_id", label: "Agent Id" },
  { name: "provider_company_name", label: "Company Name*" },
  { name: "contact_person_name", label: "Contact Person Name*" },
  { name: "total_offer", label: "Total Offers", disable: true },
  { name: "active_offer", label: "Active Offers", disable: true },
  { name: "email_address", label: "Email Address*" },
  { name: "phone", label: "Phone*" },
  { name: "postal_code", label: "Postal Code*" },
  { name: "realtor_city", label: "Realtor City*" },
  { name: "street", label: "Street*" },
  { name: "website_url", label: "Website*" },
  { name: "privacy_link", label: "Privacy*" },
  { name: "imprint", label: "Imprint*" },
];
