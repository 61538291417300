import { combineReducers } from "redux";

import { IAction, IState } from "shared/interface/state";

import loadingReducer from "./loadingReducer";

const appReducer = combineReducers({
  loading: loadingReducer,
});

const rootReducer = (state: IState | undefined, action: IAction) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
