import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// components
import {
  Button,
  Card,
  Image,
  Input,
  Loader,
  PasswordInput,
} from "@mantine/core";
// functions
import { API_CONFIG } from "shared/services/api";
import { showNotification } from "@mantine/notifications";
import HttpService from "shared/services/http.service";
// assets
import bg from "assets/images/immobilien.jpg";
import logo from "assets/images/logo.png";

const Login: React.FC = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "" });
  const [isLoading, setisLoading] = useState(false);

  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = () => {
    setisLoading(true);
    HttpService.post(API_CONFIG.path.login, loginData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        history.push("/dashboard");
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        const msg = err.response
          ? err.response.data.message
          : "Please enter valid credentials";

        showNotification({
          title: "Error",
          message: msg,
          color: "red",
        });
      });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleLogin();
    }
  };

  return (
    <div className="main-container">
      <div className="d-flex align-items-center login-container">
        <img className="login-img" src={bg} alt="img" />
        <div className="login-card">
          <Card
            shadow="sm"
            p="lg"
            radius="md"
            withBorder
            style={{ padding: "15px", height: "300px", width: "300px" }}
          >
            <Card.Section className="mt-3" style={{ marginLeft: "0" }}>
              <Image src={logo} width={215} alt="Norway" />
            </Card.Section>
            <Input
              icon={<i className="bx bx-envelope" />}
              placeholder="Your email"
              className="mt-4"
              type="email"
              value={loginData.email}
              name="email"
              onChange={handleChange}
              autoFocus
              onKeyDown={onKeyDown}
            />
            <PasswordInput
              icon={<i className="bx bxs-key" />}
              placeholder="Your Password"
              className="mt-4 mb-4"
              value={loginData.password}
              name="password"
              onChange={handleChange}
              onKeyDown={onKeyDown}
            />

            <Button
              onClick={handleLogin}
              variant="light"
              color="yellow"
              fullWidth
              mt="md"
              radius="md"
              disabled={loginData.email === "" || loginData.password === ""}
            >
              Login Now
              {isLoading && (
                <Loader size="sm" className="ml-2" variant="bars" />
              )}
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
