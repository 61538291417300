import { getDateWithTime } from "shared/util/tableConfig";

export const emailColumns = [
  {
    name: "Email",
    selector: (row: any) => row.email,
    sort: true,
    sortable: true,
  },
  {
    name: "Created Date",
    selector: (row: any) => getDateWithTime(row.createdDate),
    sort: true,
    sortable: true,
  },
  {
    name: "Updated Date",
    selector: (row: any) => getDateWithTime(row.updatedDate),
    sort: true,
    sortable: true,
  },
];
