import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// components
import { PageHeader } from "shared/components/pageHeader";
import { Button, Input } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
// functions
import HttpService from "shared/services/http.service";
import { getDateWithTime } from "shared/util/tableConfig";
import { realtorDetailfields } from "./realtorData";
// assets
import "./realtor.scss";

let realtorId = "";

const initialData = {
  provider_company_name: "",
  contact_person_name: "",
  street: "",
  count: "0",
  agent_id: "",
  postal_code: "",
  adr_city: "",
  phone: "",
  website_url: "",
  privacy_link: "",
  imprint: "",
  domain: "",
  active: false,
};

const RealtorDetails: React.FC = () => {
  const [realtorInfo, setrealtorInfo] = useState<any>({ ...initialData });
  const [isLoading, setisLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (window.location.pathname && !window.location.pathname.includes("new")) {
      realtorId = window.location.pathname.replace("/realtor/", "");
      getDetails();
    } else {
      setisLoading(false);
    }
  }, []);

  const getDetails = () => {
    setisLoading(true);

    HttpService.get(`v1/realtors/${realtorId}`, {}).then((response) => {
      setrealtorInfo(response.data);
      setisLoading(false);
    });
  };

  const handleOnChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setrealtorInfo((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    setisLoading(true);

    const payload = {
      agent_id: realtorInfo.agent_id,
      provider_company_name: realtorInfo.provider_company_name,
      contact_person_name: realtorInfo.contact_person_name,
      street: realtorInfo.street,
      postal_code: realtorInfo.postal_code,
      email_address: realtorInfo.email_address,
      match_email_address: realtorInfo.match_email_address,
      phone: realtorInfo.phone,
      realtor_city: realtorInfo.realtor_city,
      website_url: realtorInfo.website_url,
      privacy_link: realtorInfo.privacy_link,
      imprint: realtorInfo.imprint,
      active: realtorInfo.active || false,
      data_source: realtorInfo.data_source,
      partner_level: realtorInfo.partner_level,
      domain: realtorInfo.domain,
    };

    if (checkValidation()) {
      showNotification({
        message: "Please fill all the required fields to save realtor details.",
        color: "red",
      });
      setisLoading(false);
    } else {
      if (realtorId === "") {
        // add Realtor
        payload.active = true;
        addImmozyRealtor(payload);
      } else {
        // update Realtor
        updateRealtorDetails(payload);
      }
    }
  };

  const addImmozyRealtor = (payload: any) => {
    HttpService.post("v1/realtors", payload)
      .then((response) => {
        history.push(`/realtor/${response.data.id}`);
        showNotification({
          message: "Realtor added successfully",
          color: "green",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setisLoading(false);
        showNotification({
          message: error.response.data.message || "Something went wrong.",
          color: "red",
        });
      });
  };

  const updateRealtorDetails = (payload: any) => {
    HttpService.put(`v1/realtors/${realtorId}`, payload)
      .then(() => {
        getDetails();
        showNotification({
          message: "Realtor updated successfully",
          color: "green",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setisLoading(false);
        showNotification({
          message: error.response.data.message || "Something went wrong.",
          color: "red",
        });
      });
  };

  const checkValidation = () => {
    let isError = false;
    const checkvaliationArray = [
      "provider_company_name",
      "contact_person_name",
      "email_address",
      "street",
      "postal_code",
      "realtor_city",
      "phone",
      "website_url",
      "privacy_link",
      "imprint",
    ];
    checkvaliationArray.forEach((field) => {
      if (!realtorInfo[field] || realtorInfo[field] === "") {
        isError = true;
      }
    });
    return isError;
  };

  return (
    <div className="realtor-list-container">
      <PageHeader
        title={"Realtor Information"}
        isLoading={isLoading}
        backLink={"/realtor"}
      />
      <hr />
      <div className="d-flex flex-wrap">
        {realtorDetailfields.map((dataField: any, index: number) => (
          <div
            className="input-row d-flex align-items-center mt-2 mb-2"
            key={index}
          >
            <p className="realtor-data-label">{dataField.label}:</p>
            <Input
              className="mb-0 ml-3 realtor-input-box"
              name={dataField.name}
              value={
                dataField.name.includes("date")
                  ? realtorId !== ""
                    ? getDateWithTime(realtorInfo[dataField.name])
                    : ""
                  : realtorInfo[dataField.name] || ""
              }
              onChange={handleOnChange}
              placeholder="Not Added"
              disabled={dataField.disable}
            />
          </div>
        ))}
      </div>
      <div className="d-flex mt-4">
        <Button
          color="yellow"
          className="mr-2"
          size="md"
          onClick={handleSubmit}
        >
          {realtorId === "" ? "Add  Realtor" : "Save Changes"}
        </Button>
      </div>
    </div>
  );
};

export default RealtorDetails;
