import { ThunkDispatch } from "redux-thunk";
import { IAction } from "shared/interface/state";

/**
 * create action creator
 * @param ACTION - type of action
 * @param data - data
 */
export const createAction = (ACTION: string, data: any = null): IAction => ({
  type: ACTION,
  payload: data,
});

/**
 * dispatch action after given time (to handle some events like close modal after success api call)
 * @param dispatch - dispatch object
 * @param action - action type
 * @param time - time after which action is to be dispatched (default - 100ms)
 */
export const dispatchActionAfterTime = (
  dispatch: ThunkDispatch<unknown, unknown, IAction>,
  action: string,
  time = 100
) => {
  setTimeout(() => {
    dispatch(createAction(action));
  }, time);
};


export const getAuthToken = (): string | null => localStorage.getItem("token");
