interface IconClass {
  className?: string | undefined;
  fill?: string | undefined;
  width?: string;
  height?: string;
}
export const ArrowIcon = (props: IconClass) => (
  <svg
    width={props.width}
    height={props.height}
    focusable="false"
    viewBox="0 0 1000 1000"
  >
    <path d="M694 242l249 250c12 11 12 21 1 32L694 773c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210-210H68c-13 0-23-10-23-23s10-23 23-23h806L662 275c-21-22 11-54 32-33z" />
  </svg>
);

export const CloseIcon = (props: IconClass) => (
  <svg width={props.width} height={props.height} viewBox="0 0 1024 1024">
    <path
      fill="#FFB000"
      d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
    />
  </svg>
);

